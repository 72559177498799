<template>
  <div class="discover-container">
    <NavBar :title="$t('discover.rechargeLoge')" left-arrow @click-left="$router.go(-1)"></NavBar>
    <!--  -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :success-text="refreshSuccessText"
      :loosing-text="$t('home.releaseToRefresh')" :loading-text="$t('common.loading')"
      :pulling-text="$t('common.dropDownRefresh')">
      <van-list :finished="finished" :finished-text="$t('common.noMorePosts')" @load="onLoad"
        :loading-text="$t('common.loading')">
        <van-cell v-for="(item, index1) in discoverList" :key="index1">
          <div class="">
            <div>
              <div class="list-item-flex">
                <div>{{ $t('discover.refNo') }}{{ item.item.title }}</div>
                <div>
                  <span v-if="item.status === 0"> {{ $t('discover.pending') }}</span>
                  <span v-if="item.status === 1"> {{ $t('discover.successful') }}</span>
                  <span v-if="item.status === 2"> {{ $t('discover.failed') }}</span>
                </div>
              </div>
              <div class="list-item-indate list-item-flex">
                <div>
                  {{ $t('discover.remark') }}{{ item.item.remark }}
                </div>
                <div class="list-item-money-green">{{ verConfig.moneyType }} {{ item.item.amount }}</div>

              </div>
              <div class="list-item-indate" style="font-size: 12px;color:#a9a9a9">{{ dateEvent(item.indate) }}</div>
            </div>

          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { NavBar } from "vant";
import { formatDate } from '@/utils/tools'
import { getMarketingList } from '@/api/sale'
import { Toast } from "vant"
export default {
  name: '',
  components: {
    NavBar,
    Toast
  },
  props: {},
  data() {
    return {
      isshow: true,
      discoverList: [],
      index: 0,
      show: false,
      loading: false,
      finished: false,
      refreshing: false,
      pages: '1',
      refreshSuccessText: '', // 下拉刷新成功时显示的文字
      userinfo: window.localStorage.userInfo
        ? JSON.parse(window.localStorage.userInfo)
        : {},

    }
  },
  computed: {},
  watch: {},
  created() {
    this._getMarketingList()
  },
  mounted() { },
  methods: {
    dateEvent(indate) {
      return formatDate('Y-m-d H:i:s', indate)
    },
    onLoad() {
      this.pages++
      this._getMarketingList()
    },
    // 下拉刷新时触发的函数
    async onRefresh() {
      const {
        data: { data }
      } = await getMarketingList({ page: '1' })
      try {
        // 3、下拉结束之后就将下拉状态关闭
        this.refreshing = false
        // 4、下拉成功时显示提示文字
        this.refreshSuccessText = this.$t('common.successfulRenewal')
      } catch (error) {
        // 如果下拉失败，则关闭下拉状态，显示下拉失败的消息
        this.refreshing = false
        this.refreshSuccessText = this.$t('common.refreshFailed')
      }
    },
    async _getMarketingList() {
      this.loading = true
      try {
        const { ret, msg, data } = await getMarketingList({
          page: this.pages
        });

        if (ret === 1) {
          this.discoverList.push(...data.lists)
          this.loading = false
          if (this.discoverList.length === data.total) {
            this.finished = true
          }
        } else {
          this.$toast(msg)
        }
      } catch (error) {
        Toast(error.msg)
      }

    },



  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__content {
  background-color: #ff0000;

  .van-icon-arrow-left {
    color: #fff;
  }

  .van-nav-bar__title {
    color: #fff;
  }
}


.discover-container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }

  /deep/ .van-nav-bar__content {
    .van-icon {
      font-size: 40px;
      // color: #fff;
    }
  }

  .van-list {
    margin: 120px 20px;

    .van-cell {
      margin-top: 20px;
      padding: 10px 20px;
      border-radius: 20px;
      border: 1px solid #fff;
      box-shadow: 0 4px 6px -1px rgba(187, 178, 178, 0.1), 0 2px 4px -2px rgba(71, 69, 69, 0.1);

      .list-item-flex {
        display: flex;
        justify-content: space-between;
        align-items: center
      }

      .list-item-indate {
        margin-top: 6px;
        // font-size: 20px;
        // color: #888888;
      }


    }
  }
}

.list-item-money-green {
  color: #ff0000;
}
</style>
