import request, { $post } from '@/utils/request.js'

// 获取sale数据
export const getSaleList = (page) => {
  return request({
    method: 'GET',
    url: 'content/sale',
    params: page
  })
}
// 获取sale详细数据
export const getSaleInfo = (id) => {
  return request({
    method: 'GET',
    url: 'content/sale/info',
    params: id
  })
}

export const setMarketing = (params = {}) => new Promise(async (resolve, reject) => {
  try {
    const res = await $post('/marketing/gift', { act: 'user_recharge', ...params }, {})
    const { ret, msg, data } = res.data
    if (ret == 1) {
      resolve(res.data)
      return
    }
    reject(res.data)
  } catch (error) {
    reject(error)
  }
})
export const getMarketingList = (params = {}) => new Promise(async (resolve, reject) => {
  try {
    const res = await $post('/marketing/gift', {
      act: "list",
      type: "user_recharge",
      ...params
    }, {})
    const { ret, msg, data } = res.data
    if (ret == 1) {
      resolve(res.data)
      return
    }
    reject(res.data)
  } catch (error) {
    reject(error)
  }
})
